import React from 'react';
import styled from 'styled-components';
import SecuredLogo from '../assets/secured.png';
import useAutoScrollBottom from '../hooks/useAutoScrollBottom'
import { BottomContainer} from './styles'

const StyledButton = styled.button`
  background: #ffffff;
  color: #502e94;
  border-radius: 200px;
  padding: 10px 40px;
  font-size: 18px;
  border: none;
  font-weight: 600;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #502e94;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
`;

const Header = styled.h3`
  color: white;
`;

const Sub = styled.p`
  padding: 0 20px;
  text-align: center;
  color: white;
`;

const TopContainer = styled.div`
  padding-top: 60px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;


const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #502e94;
  color: white;
  object-fit: contain;
`;

/**
 * A page component that renders the the screen to prompt users to verify their email address
 * @param onVerify
 * @returns {JSX.Element}
 * @constructor
 */

export const EmailVerificationPage = ({ onVerify }) => {
  useAutoScrollBottom();
  return (
    <Container>
      <TopContainer>
        <ImageContainer>
          <img width="50%" src={SecuredLogo} />
        </ImageContainer>
        <Header>Verify Your Email</Header>
        <Sub>Please check your email for a message from us. Once your email is verified please proceed.</Sub>
      </TopContainer>
      <BottomContainer>
        <div>
          <StyledButton onClick={onVerify}>Continue</StyledButton>
        </div>
      </BottomContainer>
    </Container>
  );
};
