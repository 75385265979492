import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import api from './api';
import { LoadingPage } from './components/LoadingPage';
import { useSnackbar } from 'react-simple-snackbar';
import { useAsync } from 'react-async';
import { EmailVerificationPage } from './components/EmailVerificationPage';
import { SmsOtpEnrollmentPage } from './components/SmsOtpEnrollmentPage';
import { SmsOtpVerificationPage } from './components/SmsOtpVerificationPage';
import { GoogleAuthenticatorPage } from './components/GoogleAuthenticatorPage';
import { GoogleAuthEnrollment } from './components/GoogleAuthEnrollment';
import { GoogleAuthRecoveryPage } from './components/GoogleAuthRecovery';
import { errorSnackbarConfig, neutralSnackbarConfig } from './common/snackbar-configuration';
import { config } from './config';

function App() {
  const {
    isLoading,
    loginWithRedirect,
    handleRedirectCallback,
    isAuthenticated,
    getAccessTokenSilently,
    getIdTokenClaims,
  } = useAuth0();
  const [token, setToken] = React.useState('');
  const [googleAuthResponse, setGoogleAuthResponse] = React.useState(null);
  const [oobCode, setOobCode] = React.useState('');
  const [recoveryCode, setRecoveryCode] = React.useState('');
  // Phone Number
  const [phoneNumber, setPhoneNumber] = React.useState('');
  // step can either be:
  // 0. email-verification - default - shows the screen where the user is prompted to verify their email address
  // 1. sms-opt-enrollment - default - shows the screen where the user enters his/her phone number
  // 2. sms-otp-verification - shows the screen where the user enters the OTP sent to his/he phone number
  // 3. google-authenticator - shows the screen to optionally use google authenticator
  // 5. enroll-google-authenticator
  // 6. google-authenticator-recovery
  const [step, setStep] = React.useState('');
  /**
   * Snackbars
   */
  const [openNeutralSnackbar] = useSnackbar(neutralSnackbarConfig);
  const [openErrorSnackbar] = useSnackbar(errorSnackbarConfig);

  React.useEffect(() => {
    if (!isLoading) {
      onLoad();
    }
  }, [isLoading]);

  React.useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently().then(setToken);
    }
  }, [isAuthenticated]);

  const login = async () => {
    await loginWithRedirect({
      redirect_uri: window.location.origin,
    });
  };

  async function onLoad() {
    const query = window.location.search;
    if (query.includes('auth0_domain=') && query.includes('client_name=')) {
      const urlParams = new URLSearchParams(window.location.search);
      window.localStorage.setItem('original_state', urlParams.get('state'));
      await login();
      return;
    }
    if (query.includes('code=') && query.includes('state=')) {
      await handleRedirectCallback();
      window.history.replaceState({}, document.title, '/');
      return;
    }
    checkStartingPage();
  }

  async function onVerify() {
    window.location.reload();
  }

  /**
   * Determines whether the email-verification page is shown or the sms-otp-enrollment
   * @returns {Promise<void>}
   */
  async function checkStartingPage() {
    const response = await getIdTokenClaims();
    if (response) {
      setStep('sms-otp-enrollment');
      if (response.email_verified) {
        setStep('sms-otp-enrollment');
      } else {
        setStep('email-verification');
      }
    }
  }

  /**
   * Attempts to enroll the user's phone number to the user's authenticators
   * @returns {Promise<void>}
   */
  const associatePhoneNumberAction = useAsync({
    onResolve: (resend) => {
      if (!!resend) {
        openNeutralSnackbar('Your code has been resent.');
      } else {
        openNeutralSnackbar('We have sent an OTP to your phone number.');
        setStep('sms-otp-verification');
      }
    },
    onReject: (error) => {
      openErrorSnackbar(error.message);
    },
    deferFn: async ([phoneNumber, resend]) => {
      const response = await api.post(
        '/mfa/associate',
        {
          authenticator_types: ['oob'],
          oob_channels: ['sms'],
          phone_number: phoneNumber,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        },
      );
      setOobCode(response.data.oob_code);
      if (response.data.recovery_codes) {
        setRecoveryCode(response.data.recovery_codes[0]);
      }
      return resend;
    },
  });

  const enrollGoogleAuth = useAsync({
    onResolve: (response) => {
      setGoogleAuthResponse(response.data);
      setStep('enroll-google-authenticator');
    },
    deferFn: async () => {
      const response = await api.post(
        '/mfa/associate',
        {
          authenticator_types: ['otp'],
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        },
      );
      return response;
    },
  });

  const submitGoogleAuthOtp = useAsync({
    onReject: (error) => {
      if (error?.message.includes('403')) {
        openErrorSnackbar('Invalid OTP code. Please try again.');
      } else {
        // handle unexpected error messages
        openErrorSnackbar('We cannot process your request. Please try again later.');
      }
    },
    onResolve() {
      openNeutralSnackbar('OTP successfully enrolled');
      if (recoveryCode) setStep('google-authenticator-recovery');
      else {
        onFinishMfa();
      }
    },
    deferFn: async ([otp]) => {
      const response = await api.post('/oauth/token', {
        mfa_token: token,
        otp: otp,
        grant_type: 'http://auth0.com/oauth/grant-type/mfa-otp',
        client_id: config.clientId,
      });
      if (!response.data.access_token) {
        throw new Error('Unable to verify OTP. Please try again!');
      }
      return response;
    },
  });
  const submitPhoneNumberOTPAction = useAsync({
    onReject: (error) => {
      if (error?.message.includes('403')) {
        openErrorSnackbar('Invalid Code. Please try again.');
      } else {
        // handle unexpected error messages
        openErrorSnackbar('We cannot process your request. Please try again later.');
      }
    },
    onResolve: () => {
      openNeutralSnackbar('Your phone number has been verified!');
      setStep('google-authenticator');
    },
    deferFn: async ([otp]) => {
      const response = await api.post('/oauth/token', {
        mfa_token: token,
        oob_code: oobCode,
        binding_code: otp,
        grant_type: 'http://auth0.com/oauth/grant-type/mfa-oob',
        client_id: config.clientId,
      });
      if (!response.data.access_token) {
        throw new Error('Unable to verify OTP. Please try again!');
      }
      return response;
    },
  });

  // finish workflow
  function onFinishMfa() {
    window.location = `https://${config.domain}/continue?state=${window.localStorage.getItem(
      'original_state',
    )}`;
  }

  /**
   * If Auth0 is still loading, show the [Loading Page]
   */
  if (isLoading || (isAuthenticated && !step) || !isAuthenticated) {
    return <LoadingPage />;
  }

  if (step === 'email-verification') {
    return <EmailVerificationPage onVerify={onVerify} />;
  }

  if (step === 'sms-otp-enrollment') {
    return (
      <SmsOtpEnrollmentPage
        associatePhoneNumberAction={associatePhoneNumberAction}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
      />
    );
  }

  if (step === 'sms-otp-verification') {
    return (
      <SmsOtpVerificationPage
        submitPhoneNumberOTPAction={submitPhoneNumberOTPAction}
        associatePhoneNumberAction={associatePhoneNumberAction}
        phoneNumber={phoneNumber}
      />
    );
  }

  if (step === 'google-authenticator') {
    return <GoogleAuthenticatorPage onFinishMfa={onFinishMfa} enrollGoogleAuthAction={enrollGoogleAuth} />;
  }

  if (step === 'enroll-google-authenticator') {
    return <GoogleAuthEnrollment onFinishMfa={onFinishMfa}  googleAuthResponse={googleAuthResponse} submitGoogleAuthOtp={submitGoogleAuthOtp} />;
  }

  if (step === 'google-authenticator-recovery') {
    return (
      <GoogleAuthRecoveryPage
        code={recoveryCode}
        onContinue={onFinishMfa}
      />
    );
  }
}

export default App;
