import React from 'react';
import {
  BottomContainer,
  ButtonsContainer,
  Label,
  Logo,
  LogoContainer,
  MainContainer,
  PhoneNumberFormContainer,
  StyledButton,
  TopContainer,
  TopContainerText,
  TextButton
} from './styles';
import logo from '../assets/logo_white_background.png';
import styled from 'styled-components';
import { AsYouType } from 'libphonenumber-js';

const Input = styled.input`
  outline: none;
  background: transparent;
  border: none;
  border-bottom: 2px solid #9c7ae1;
  height: 45px;
  color: white;
  font-size: 18px;
  text-align: left;
  font-weight: 300;
  font-family: Roboto, serif;
  letter-spacing: 20px;

  &:active &:focus-visible {
    border: none;
  }
`;


export const SmsOtpVerificationPage = ({ phoneNumber, submitPhoneNumberOTPAction, associatePhoneNumberAction }) => {
  const [otpCode, setOtpCode] = React.useState('');
  const loading = submitPhoneNumberOTPAction.isPending;
  const formattedPhoneNumber = new AsYouType().input('+' + phoneNumber);
  return (
    <MainContainer>
      <TopContainer>
        <LogoContainer>
          <Logo src={logo} />
        </LogoContainer>
        <TopContainerText>Enter Verification Code</TopContainerText>
        <TopContainerText>
          Enter the code sent to <b>{formattedPhoneNumber}</b>
        </TopContainerText>
        <PhoneNumberFormContainer>
          <Label>Verification Code</Label>
          <Input maxLength={6} inputMode={'numeric'} onChange={(e) => setOtpCode(e.target.value)} />
        </PhoneNumberFormContainer>
      </TopContainer>
      <BottomContainer>
        <ButtonsContainer>
          <StyledButton
            onClick={() => {
              submitPhoneNumberOTPAction.run(otpCode);
            }}
            disabled={otpCode.length !== 6 || loading}
          >
            {loading ? 'Loading' : 'Continue'}
          </StyledButton>
          <TextButton
            onClick={() => {
              associatePhoneNumberAction.run(`+${phoneNumber}`, true);
            }}
          >
            {associatePhoneNumberAction.isPending ? 'Loading...' : "Didn't Receive The Code?"}
          </TextButton>
        </ButtonsContainer>
      </BottomContainer>
    </MainContainer>
  );
};
